<template>
  <div>

    <div>
      <span class="ghost">{{ showDialog2 }}</span>
      <v-snackbar
        v-for="(notification, index) in this.$store.state.notifications"
        :key="index"
        :timeout="4000"
        :color="notification.type === 'success' ? 'success' : 'error'"
        v-model="notification.show"
        top
        right
        text
        @input="removeNotification(index)"
        class="mt-5"
      >
        {{ notification.message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            @click="notification.show = false"
            :color="notification.type === 'success' ? 'success' : 'error'"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
          <v-card-title>Notificationas Abiertas</v-card-title>
          <v-card-text>
            <ul>
              <li v-for="(notification, index) in this.$store.state.notifications" :key="index">
                {{ notification.message }}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="showDialog = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-btn class="bx1" @click="isOpened = !isOpened"> </v-btn>
    <div class="sidebar" :class="isOpened ? 'open' : ''" :style="cssVars">
      <div class="logo-details" style="margin: 6px 14px 0 14px;">
        <!-- <img v-if="menuLogoGlya" :src="logoGlya" alt="menu-logo" class="menu-logo-glya icon"> -->
        <router-link to="/dashboard" v-if="menuLogo">
          <img :src="logo" alt="menu-logo" class="menu-logo icon">
        </router-link>
        <i v-else class="bx icon" :class="menuIcon" />
        <i class="bx bx-padding" :class="isOpened ? 'bx-menu-alt-right' : 'bx-menu'" id="btn" @click="isOpened = !isOpened" />
      </div>

      <div
        style="display: flex ; flex-direction:column; justify-content: space-between; flex-grow: 1; max-height: calc(100% - 60px); ">
        <div class="my-scroll">
          <ul class="nav-list" style="overflow: visible;">
            <v-tooltip bottom color="indigo lighten-3" class="toll-search" nudge-bottom="-15">
              <template v-slot:activator="{ on, attrs }">
                <li v-if="isSearch" @click="isOpened = true"   v-bind="attrs"
                v-on="on">
                  <i class="bx bx-search" @click="patientSearch" />
                  <input type="search" placeholder="Buscar paciente..." @search="patientSearch" @keyup.enter="patientSearch"
                    v-model="inputpatient">
                </li>
              </template>
              <span>Buscar por nombre o documento</span>
            </v-tooltip>
           
            <!-- <span v-for="(menuItem, index) in menuItems" :key="index" >
              <li>
                <router-link :to="menuItem.link"><i class="bx" :class="menuItem.icon || 'bx-square-rounded'" />
                  <span class="links_name">{{ menuItem.name }}</span>
                </router-link> 
              </li>
            </span> -->
            <div @mouseover="isOpened = true">              
              <span v-for="(menuItemFather, index) in menuItems" :key="index">                
                <li>
                  <div v-if="menuItemFather.component">                    
                    <a>
                      <FormBasicData :createsForm="true" modal_name="Nuevo Paciente" :showbutton="true" :showicon="false"
                        label="Nuevo Paciente" icon="bx-user-plus"></FormBasicData>
                    </a>
                  </div>
                  <div v-if="!menuItemFather.component">
                    <div @click="menuItemFather.expand = !menuItemFather.expand">                    
                      <router-link :to="menuItemFather.link" v-if="menuItemFather.children.length == 0"><i class="bx"
                          :class="menuItemFather.icon || 'bx-square-rounded'" />
                        <span class="links_name">{{ menuItemFather.name }}</span>
                      </router-link>
                      <button v-else><i class="bx" :class="menuItemFather.icon || 'bx-square-rounded'" />
                        <span class="links_name">{{ menuItemFather.name }}</span>
                        <i class='bx chevron-position' :class="menuItemFather.expand == false ? 'bx-chevron-down' : 'bx-chevron-up'" ></i>
                      </button>
                    </div>  
                    <v-expand-transition>
                      <v-card v-show="menuItemFather.expand == true" color="sub-menu-color" class="mx-auto sub-menu"
                        rounded="false" elevation="1" v-if="menuItemFather.children.length > 0">
                        <span v-for="(menuItem, index) in menuItemFather.children" :key="index">
                          <ul>
                            <li>
                              <div>
                                <router-link class="sub-menu-item" :to="menuItem.link"><i class="bx"
                                    :class="menuItem.icon || 'bx-square-rounded'" />
                                  <span class="links_name" @click="menuItemFather.expand = !menuItemFather.expand">{{
                                  menuItem.name
                                  }}</span>
                                </router-link>
                              </div>
                            </li>
                          </ul>
                        </span>
                      </v-card>
                    </v-expand-transition>                    
                  </div>
                </li>
              </span>


            </div>

          </ul>
        </div>
      </div>
      <div class="opinion" style="height: 100px">
  
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ma-3 btn-opinion" outlined color="white" rounded small >
              <!-- <span class="icon"><img class="icon" src="../../assets/feedback.svg" alt="Caja de opiniones"></span> -->
              <v-icon class="icon-opinion" small color="white">mdi-comment-text-outline</v-icon>
              <span class="text-opinion">Cuentanos tu opinión</span>
            </v-btn>
          </template>
    
          <v-card>
            <v-card-text class="pt-5">
              <h3 class="pb-4">Cuéntanos tu opinión</h3>
              <p>¿Cómo ha sido tu experiencia utilizando Glya PXM?</p>
              <v-col cols="6" offset="3" class="mb-5">
                <v-row class="d-flex justify-space-between">
                  <div v-for="button in facebuttons" :key="button.value">
                    <v-btn @click="qualification = button.value" :alt="button.text" icon :color="qualification == '' || button.value == qualification ? button.color : 'grey lighten-2'"><v-icon x-large>{{button.icon}}</v-icon></v-btn>
                  </div>
                </v-row>
              </v-col>
              <v-col class="text-center">
                <v-btn v-if="opinion==false" @click="opinion=true" small color="indigo lighten-3" block dark>Dejar un comentario</v-btn>
              </v-col>
              <div v-if="opinion==true">
                <p>¡Tu opinión es muy importante para nosotros! Cuéntanos sobre tu experiencia y danos sugerencias para mejorar.</p>
                <v-textarea v-model="message" type="text" placeholder="Sugerencias" outlined="outlined" rows="3" auto-grow counter ></v-textarea>   
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn @click="clearData" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
              <div class="ml-3">
                  <v-btn v-if="qualification=='' && message==''" disabled class="pl-5 pr-5 white--text" depressed color="green lighten-1" small > Enviar <v-icon right dark > mdi-check</v-icon></v-btn>
                  <v-btn v-else @click="send" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small > Enviar <v-icon right dark > mdi-check</v-icon></v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
  var arrayIds = undefined
  import logo from '@/assets/glyapxm-01.svg'
  import logoGlya from '@/assets/glyapxm-01.svg'
  import store from '@/store'
  import FormBasicData from "@/components/forms/FormBasicData.vue";
  import AxiosServices from '@/services/AxiosServices';

  export default {
    name: 'SidebarGlya',
    props: {
      //! Menu settings
      isMenuOpen: {
        type: Boolean,
        default: true,
      },
      menuTitle: {
        type: String,
        default: 'GDI',
      },
      menuLogo: {
        type: String,
        default: ('./assets/glyapxm-01.svg'),
      },
      menuLogoGlya: {
        type: String,
        default: ('./assets/glyapxm-01.svg'),
      },
      menuIcon: {
        type: String,
        default: 'bxl-c-plus-plus',
      },
      isPaddingLeft: {
        type: Boolean,
        default: true,
      },
      menuOpenedPaddingLeftBody: {
        type: String,
        default: '250px'
      },
      menuClosedPaddingLeftBody: {
        type: String,
        default: '50px'
      },

      //! Menu items
      menuItems: {
        type: Array,
        default: () => [
          // {
          //   link: '/activities',
          //   name: 'Actividades',
          //   tooltip: 'Actividades',
          //   icon: 'bx-grid-alt',
          //   children:[]
          // },add
          {
            link: '/',
            name: 'Dashboard',
            tooltip: 'Dashboard',
            icon: 'bx-home',
            children: [],
            component:false
          },
          {  
            link: '/',          
            name: 'Nuevo Paciente',
            tooltip: 'Dashboard',
            icon: 'bx-home',
            children: [],
            component:true
          },
          {  
            link: '/patients',          
            name: 'Pacientes',
            tooltip: 'Pacientes',
            icon: 'bx-user',
            children: [],
            component:false
          },
          {
            link: '/orders',
            name: 'Gestión de órdenes',
            tooltip: 'Gestión de órdenes',
            icon: 'bx-folder',
            children: [],
            component:false
          },
          {
            link: '/alerts_management',
            name: 'Gestión de alertas',
            tooltip: 'Gestión de alertas',
            icon: 'bx-timer',
            children: [],
            component:false
          },
          // {            
          //   name: 'Inventario',
          //   tooltip: 'Inventario',
          //   icon: 'bx-package',
          //   expand: false,
          //   children: [
          //     {
          //       link: '/inventory',
          //       name: 'Stock',
          //       tooltip: 'Stock',
          //       icon: 'bx-archive'
          //     },
          //     {
          //       link: '/moves',
          //       name: 'Movimientos',
          //       tooltip: 'Movimientos',
          //       icon: 'bx-transfer-alt'
          //     }

          //   ]
          // },
          {    
            name: 'Configuración',
            tooltip: 'Configuración',
            icon: 'bx-collection',
            expand:false,
            children:[
              {
                link: '/users',
                name: 'Usuarios',
                tooltip: 'Usuarios',
                icon: 'bx-user'
              },
              {
                link: '/alerts',
                name: 'Alertas',
                tooltip: 'Alertas',
                icon: 'bx-alarm-add'
              },
              {
                link: '/tags',
                name: 'Etiquetas',
                tooltip: 'Etiquetas',
                icon: 'bx-purchase-tag-alt'
              },
              {
                link: '/webhooks',
                name: 'HL7 FHIR Webhooks',
                tooltip: 'HL7 FHIR Webhooks',
                icon: 'bx-meteor'
              }

            ],
            component:false
          },
          // {
          //   name: 'Informes',
          //   tooltip: 'Informes',
          //   icon: 'bx-file',
          //   expand: false,
          //   children: [
          //     {
          //       link: '/reports',add
          //       name: 'Adminsitrador de Descargas',
          //       tooltip: 'Adminsitrador de Descargas',
          //       icon: 'bx-cloud-download'
          //     }
          //   ]
          // }          
        ],
      },

      //! Search
      isSearch: {
        type: Boolean,
        default: true,
      },

      //! Styles
      bgColor: {
        type: String,
        default: '#303F9F',
      },
      secondaryColor: {
        type: String,
        default: '#1d1b31',
      },
      homeSectionColor: {
        type: String,
        default: '#e4e9f7',
      },
      logoTitleColor: {
        type: String,
        default: '#fff',
      },
      iconsColor: {
        type: String,
        default: '#fff',
      },
      itemsTooltipColor: {
        type: String,
        default: '#e4e9f7',
      },
      searchInputTextColor: {
        type: String,
        default: '#fff',
      },
      menuItemsHoverColor: {
        type: String,
        default: '#fff',
      },
      menuItemsTextColor: {
        type: String,
        default: '#fff',
      },
      menuFooterTextColor: {
        type: String,
        default: '#fff',
      },
    },
    data() {
      return {
        inputpatient:"",
        isOpened: false,
        ids:arrayIds,
        logo,
        logoGlya,
        storeprueba:store,
        window: {
            width: 0
        },
        showDialog:false,
        dialog:false,
        message : '',
        opinion:false,
      qualification:'',
      facebuttons:[
        {value:1, text:'Positiva', icon:'mdi-emoticon-happy-outline', color:'green'},
        {value:2, text:'Normal', icon:'mdi-emoticon-neutral-outline', color:'orange'},
        {value:3, text:'Negativa', icon:'mdi-emoticon-sad-outline', color:'error'}
      ]
      }
    },
    created() {
        window.addEventListener('resize', this.menuResponsive);
        this.menuResponsive();
        // this.$store.dispatch("putVaccines")
    },
    destroyed() {
        window.removeEventListener('resize', this.menuResponsive);
    },

    mounted() {
      this.isOpened = this.isMenuOpen
      this.clearData()
    },
    methods: {
      send:function(){
        let feedback = {message:this.message, qualification:this.qualification}
        AxiosServices.feedback(feedback)
        .then(res => {
          console.log('envio',res);
          this.$store.dispatch("addNotification", res.data);
          this.clearData()
        }).catch(error => {
          console.log('error',error);
          this.clearData()
        })
      },
      clearData:function(){
        this.dialog = false
        this.message = ''
        this.qualification = ''
        this.opinion = false
      },
      menuResponsive() {
        if (!this.isMobile()) {
          this.window.width = window.innerWidth;
          if (this.window.width < 900) {
            this.isOpened = false;
          } else {
            this.isOpened = true;
          }
        }
      },
      isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      },
      patientSearch: function(){
        if (this.inputpatient != '' && this.inputpatient != null){
          this.inputpatient = this.inputpatient.replace(/(^\s+|\s+$|\t+|&#160;+|\xA0)/g, '');
          this.$router.push({ name: 'patients',query:{q:this.inputpatient} }).catch(()=>{})
        }
        this.inputpatient=''
      },
      removeNotification:function(index){
        this.$store.dispatch("removeNotification",index)
      },
      changeShowDialog(show){
        this.showDialog=show
      }
    },
    computed: {
      cssVars() {
        return {
          // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
          '--bg-color': this.bgColor,
          '--secondary-color': this.secondaryColor,
          '--home-section-color': this.homeSectionColor,
          '--logo-title-color': this.logoTitleColor,
          '--icons-color': this.iconsColor,
          '--items-tooltip-color': this.itemsTooltipColor,
          '--serach-input-text-color': this.searchInputTextColor,
          '--menu-items-hover-color': this.menuItemsHoverColor,
          '--menu-items-text-color': this.menuItemsTextColor,
          '--menu-footer-text-color': this.menuFooterTextColor,
        }
      },
      showDialog2:function(){
        var show=false
        if(this.$store.state.notifications.length>2){
          show=true
        }
        // this.changeShowDialog(show)
        return show
      }
    },
    components: {
      FormBasicData,

    },    
    watch: {
      isOpened() {
        window.document.body.style.paddingLeft = this.isOpened && this.isPaddingLeft ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody
      }
    }
  }
</script>

<style>
/* Google Font Link */
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');

.my-scroll ul {
  padding-left: 0px !important;
}

.menu-logo {
  width: 80px;
  margin: 0 10px 0 10px;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  /* overflow-y: auto; */
  width: 50px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.5s ease;
}
.opinion{
  height: 100%;
  min-height: min-content;
  width: 50px;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 120px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon, 
.opinion {
  opacity: 0;
  display: none;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: var(--logo-title-color);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name,
.sidebar.open .opinion {
  opacity: 1;
  display: block;
}


.sidebar .logo-details #btn {
  position: absolute;
  top: 15px;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: var(--items-tooltip-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1A237E;
  margin-right: 10px;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1A237E;
  color: var(--icons-color);
}

.sidebar.open .bx-search:hover {
  background: var(--secondary-color);
  color: var(--icons-color);
}

.sidebar .bx-search:hover {
  background: var(--menu-items-hover-color);
  color: var(--bg-color);
}

.sidebar li a, .sidebar li button {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  /* background: var(--bg-color); */
}

.sidebar li a:hover {
  background: var(--menu-items-hover-color);
}
.sidebar li button:hover {
  background: var(--menu-items-hover-color);
}

.sidebar li a .links_name, .sidebar li button .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name, .sidebar.open li button .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name, .sidebar li button:hover .links_name,
.sidebar li a:hover i, .sidebar li button:hover i {
  transition: all 0.5s ease;
  color: var(--bg-color);
}
.sidebar li button:hover .links_name,
.sidebar li button:hover i {
  transition: all 0.5s ease;
  color: var(--bg-color);
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar div.profile {
  position: relative;
  height: 60px;
  width: 78px;
  /* left: 0;
    bottom: 0; */
  padding: 10px 14px;
  background: var(--secondary-color);
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open div.profile {
  width: 250px;
}

.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar div img {
  height: 130px;
  width: 160px;
  object-fit: contain;
  border-radius: 6px;
  margin-right: 20px;
  margin-left: 30px;
  margin-top: 50px;

}

.sidebar div .menu-logo-glya {

  width: 50px;
  position: absolute;
  top: -80px;

}

.sidebar div.profile .name,
.sidebar div.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--menu-footer-text-color);
  white-space: nowrap;
}

.sidebar div.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 0;
}

.sidebar.open .profile:hover #log_out {
  opacity: 1;
}

.sidebar.open .profile #log_out:hover {
  opacity: 1;
  color: red;
}

.sidebar .profile #log_out:hover {
  color: red;
}

.home-section {
  position: relative;
  background: var(--home-section-color);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: var(--bg-color);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.my-scroll-active {
  overflow-y: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.my-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 60px);
  scrollbar-width: auto;
  scrollbar-color: #8e98fd #2D1793;
  margin-bottom: 120px;
}

/* Chrome, Edge, and Safari */
.my-scroll::-webkit-scrollbar {
  width: 8px;
}

.my-scroll::-webkit-scrollbar-track {
  background: #2D1793
}

.my-scroll::-webkit-scrollbar-thumb {
  background-color: #8e98fd;
  border-radius: 10px;
  border: 2px solid #2D1793;
}

.bx1 {
  display: none !important;
}

.my-scroll .v-application ul,
.v-application ol {
  padding-left: 0px;
}

.sidebar li .button-new .showbutton,
.sidebar li .button-new {
  width: 50px;
  transition: all 0.5s ease;
}

.sidebar.open li .button-new .showbutton,
.sidebar.open li .button-new {
  width: 95%;
  transition: all 0.5s ease;
  margin: 3px;
}

.sidebar li .button-new button span span {
  opacity: 0;
}

.sidebar.open li .button-new button span span {
  opacity: 1;
}

.sub-menu {
  width: auto;
  height: auto;
  padding-left: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 0px;

}

.sub-menu>span>ul>li>div {
  height: 30px;
}

.sub-menu-color {
  background-color: #202c79 !important;

}

.bx-padding {

  padding-left: 20px;
}

.sidebar li .sub-menu-item:hover {
  background: #5144e33d;
}

.sidebar li .sub-menu-item:hover .links_name,
.sidebar li .sub-menu-item:hover i {
  transition: all 0.5s ease;
  color: #EBEBEE;
}

.chevron-position{

  position: absolute;
left: 200px;
}

@media (max-width: 420px) {

  .sidebar li .tooltip {
    display: none;
  }

  .bx1 {
    display: block !important;
    margin-top: -50px;
    margin-bottom: 20px;
    margin-left: -5px;
    z-index: 1;
    width: 30px;
    background: #2D1793 !important;
    color: #ffffff !important;
  }

  .sidebar {
    width: 0px;
  }

  .bx-menu {
    width: 200px;
    left: -80px;
    margin-top: 8px;
  }

  body {
    padding-left: 0px !important
  }

}
/* css cuentanos tu opinion */

.text-opinion {
color: rgb(255, 255, 255);
font-size: 11px !important;
}
.icon-opinion {
 
  height: 30px !important ;
  min-width: 30px !important;
}
.opinion{
  height: 50px;
  position: absolute;
  bottom: -50px;
  background: #303f9f;

}
</style>