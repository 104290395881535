import Api from '@/services/Api'

export default{

    feedback(message) {
        return Api().post(`/api/v1/feedback`, message)
    },
    authLogin(user) {
        return Api().post('/api/v1/sessions', user)
    },
    request_otp(nid){
        return Api().post('/api/v1/request_otp', nid)
    },
    verify_otp(nid_otp){
        return Api().post('/api/v1/verify_otp', nid_otp)
    },
    userList(params) {
        return Api().get(`/api/v1/users` , {params});
    },
    userCreate(user) {
        return Api().post(`/api/v1/users`, user)
    },
    userUpdate(user_id, user) {
        return Api().put(`/api/v1/users/${user_id}`, user)
    },
    userDetail(user_id) {
        return Api().get(`/api/v1/users/${user_id}`);
    },
    patientDetail(patient_id){
        return Api().get(`/api/v1/patients/${patient_id}`);
    },
    patientExportPDF(patient_id){
        return Api().get(`/api/v1/patients/${patient_id}/show_pdf`);
    },
    patientExportCSV(patient_id){
        return Api().get(`/api/v1/patients/${patient_id}/measurements?format=csv`);
    },
    patientAlertsList(patient_id, params) {
        return Api().get(`/api/v1/patients/${patient_id}/alerts`,{ params });
    },
    patientAlertsDetail(patient_id,alert_id) {
        return Api().get(`/api/v1/patients/${patient_id}/alerts/${alert_id}`);
    },
    patientAlertsUpdate(patient_id,alert_id,alert) {
        return Api().put(`/api/v1/patients/${patient_id}/alerts/${alert_id}`, alert)
    },
    patientAlertsEntriesCreate(patient_id,alert_id,entry){
        return Api().post(`/api/v1/patients/${patient_id}/alerts/${alert_id}/alert_entries`, entry)
    },
    patientAlarmsList(patient_id,clinical_element="empty"){
        if (clinical_element=="empty")
            return Api().get(`/api/v1/patients/${patient_id}/alarms`);
        else
            return Api().get(`/api/v1/patients/${patient_id}/alarms?clinical_element=${clinical_element}`);
    },
    patientAlarmsCreate(patient_id,alarm) {
        return Api().post(`/api/v1/patients/${patient_id}/alarms`, alarm)
    },
    patientOrdersList(patient_id, params){
        return Api().get(`/api/v1/patients/${patient_id}/orders`, {params});
    },
    patientOrdersDetail(patient_id,order_id) {
        return Api().get(`/api/v1/patients/${patient_id}/orders/${order_id}`);
    },
    patientOrdersCreate(patient_id, order) {
        return Api().post(`/api/v1/patients/${patient_id}/orders`, order)
    },
    patientOrdersUpdate(patient_id, order_id,order) {
        return Api().put(`/api/v1/patients/${patient_id}/orders/${order_id}`,order)
    },
    patientEncountersList(patient_id,order_id){
        return Api().get(`/api/v1/patients/${patient_id}/orders/${order_id}/encounters`)
    },
    patientActivityAllList(patient_id, params){
        return Api().get(`/api/v1/patients/${patient_id}/activities`, {params})
    },    
    patientActivityByOrder(patient_id,order_id){
        return Api().get(`/api/v1/patients/${patient_id}/orders/${order_id}/activities`)
    },    
    patientOrdersActivitiesUpdate(patient_id, order_id, activity_id,activity) {
        return Api().put(`/api/v1/patients/${patient_id}/orders/${order_id}/activities/${activity_id}`,activity)
    },
    patientMeasurementsList(patient_id,type) {
        return Api().get(`/api/v1/patients/${patient_id}/measurements?clinical_element=${type}`)
    },
    patientCaregiversList(patient_id) {
        return Api().get(`/api/v1/patients/${patient_id}/caregivers`)
    },
    patientCaregiversNew(patient_id,id_type,nid) {
        return Api().get(`/api/v1/patients/${patient_id}/caregivers/new?identification_type=${id_type}&identification_number=${nid}`)
    },
    patientCaregiversCreate(patient_id, caregiver) {
        return Api().post(`/api/v1/patients/${patient_id}/caregivers`, caregiver)
    },
    patientCaregiversUpdate(patient_id, caregiver_id, caregiver) {
        return Api().put(`/api/v1/patients/${patient_id}/caregivers/${caregiver_id}`, caregiver)
    },
    patientTagsList(patient_id) {
        return Api().get(`/api/v1/patients/${patient_id}/tags?patient_id=${patient_id}`)
    },
    patientTagsCreate(patient_id,tag){
        return Api().post(`/api/v1/patients/${patient_id}/tags?owner=patient&patient_id=${patient_id}`, tag)
    },
    patientSearch(query) {
        return Api().get(`/api/v1/patients?q=${query}`)
    },
    patientList() {
        return Api().get(`/api/v1/patients`)
    },
    patientListFilters(params) {
        return Api().get(`/api/v1/patients`,{params})
    },    
    patientsCreate(patient) {
        return Api().post('/api/v1/patients', patient)
    },
    patientValidate(id_type,nid) {
        return Api().get(`/api/v1/patients/new?identification_type=${id_type}&identification_number=${nid}`)
    },
    patientUpdate(patient_id,patient) {
        return Api().put(`/api/v1/patients/${patient_id}`,patient)
    },
    clientDetail(client_id) {
        return Api().get(`/api/v1/clients/${client_id}`);
    },
    clientValidate(client_id) {
        return Api().get(`/api/v1/clients/${client_id}/validate_webhook`);
    },
    clientUpdate(client_id, client) {
        return Api().put(`/api/v1/clients/${client_id}`, client)
    },
    clientOrdersList(client_id, params){
        return Api().get(`/api/v1/clients/${client_id}/orders`,{params})
    },
    clientAlertsList(client_id,type){
        return Api().get(`/api/v1/reports/client_alerts_list?client_id=${client_id}&type=${type}`)
    },
    clientAlertsClosedList(params){
        return Api().get(`/api/v1/reports/client_alerts_list`,{ params })
    },
    clientTagsList(params) {
        return Api().get(`/api/v1/tags`,{ params })
    },
    clientTagsCreate(tag){
        return Api().post(`/api/v1/tags?owner=client`, tag)
    },
    clientAlarmsCreate(alarm) {
        return Api().post(`/api/v1/alarms?owner=client`, alarm)
    },
    clientAlarmsUpdate(alarm_id,alarm) {
        return Api().put(`/api/v1/alarms/${alarm_id}`, alarm)
    },
    clientAlarmsList(params) {
        return Api().get(`/api/v1/alarms`,{ params })
    },
    listingsSimple(type){
        return Api().get(`/api/v1/listings?type=${type}`)
    },
    listingsSimpleCountry(type,country){
        return Api().get(`/api/v1/listings?type=${type}&country_id=${country}`)
    },
    listingsSearch(params){
        return Api().get(`/api/v1/listings?`,{params})
    },
    listingsSearchCities(type,country,q){
        return Api().get(`/api/v1/listings?type=${type}&country_id=${country}&q=${q}`)
    },
    listingsAll(){
        return Api().get(`/api/v1/listings`)
    },
    tagsDetail(tag_id){
        return Api().get(`/api/v1/tags/${tag_id}`)
    },
    alarmsDetail(alarm_id) {
        return Api().get(`/api/v1/alarms/${alarm_id}`)
    },
    createActivitiesByPatientOrder(patient_id,order_id) {
        return Api().post(`/api/v1/patients/${patient_id}/orders/${order_id}/activities`)
    },
    medicinesDetail(medicine_id){
        return Api().get(`/api/v1/medicines/${medicine_id}`)
    },


//  REPORTES
    reportsClientAlerts(clinical_element, start_date, end_date){
        return Api().get(`/api/v1/reports/client_alerts?clinical_element=${clinical_element}&start_date=${start_date}&end_date=${end_date}`)
    },
    reportsClientAlertsOpenedMetrics(client_id){
        return Api().get(`/api/v1/reports/client_alerts_opened_metrics?client_id=${client_id}`)
    },
    reportsClientMeasurementsAdherence(client_id,start_date, end_date){
        return Api().get(`/api/v1/reports/client_measurements_adherence?client_id=${client_id}&start_at=${start_date}&end_at=${end_date}`)
    }
}