<template>
    <div>
        <v-select 
            v-model="selectItems" 
            :items="items"
            item-value="id"
            item-text="text"        
            :chips="chips"
            :label="label_show"
            :multiple="multiple"
            :error-messages="nameErrors"
            @input="emitSelection"
            :class="filter"
            :outlined="outlined"
        ></v-select>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    export default {
        props:{
            label: {
                type: String,
                default: 'select múltiple vacio'
            },
            outlined: { 
                default: false 
            },
            multiple: { 
                default: false
            },
            chips: { 
                default: false
            },
            validador: {
                default: 'none'
            },
            list_type:  {
                type:String,
                default:'días'
            },
            filter:{}         
        },
        data() {
            return {
                selectItems: this.$attrs.value,
                items: [{id:1,text:'Uno'},{id:2,text:'dos'},{id:3,text:'tres'}],
                value: this.$attrs.value,
                val:this.$props.validador,
                fromvuex: this.$store.state.validations2[this.$props.validador],
                minl: 5,
                list:{
                    días:[
                        {id:0,text:"Domingo"},
                        { id: 1, text: "Lunes" },
                        { id: 2, text: "Martes" },
                        { id: 3, text: "Miércoles" },
                        { id: 4, text: "Jueves" },
                        { id: 5, text: "Viernes" },
                        { id: 6, text: "Sábado" }
                    ],
                    horas:[
                        { id: '06:00', text: "06:00" },{ id: '06:30', text: "06:30" },{ id: '07:00', text: "07:00" },{ id: '07:30', text: "07:30" },{ id: '08:00', text: "08:00" },{ id: '08:30', text: "08:30" },
                        { id: '09:00', text: "09:00" },{ id: '09:30', text: "09:30" },{ id: '10:00', text: "10:00" },{ id: '10:30', text: "10:30" },{ id: '11:00', text: "11:00" },{ id: '11:30', text: "11:30" },
                        { id: '12:00', text: "12:00" },{ id: '12:30', text: "12:30" },{ id: '13:00', text: "13:00" },{ id: '13:30', text: "13:30" },{ id: '14:00', text: "14:00" },{ id: '14:30', text: "14:30" },
                        { id: '15:00', text: "15:00" },{ id: '15:30', text: "15:30" },{ id: '16:00', text: "16:00" },{ id: '16:30', text: "16:30" },{ id: '17:00', text: "17:00" },{ id: '17:30', text: "17:30" },
                        { id: '18:00', text: "18:00" },{ id: '18:30', text: "18:30" },{ id: '19:00', text: "19:00" },{ id: '19:30', text: "19:30" },{ id: '20:00', text: "20:00" },{ id: '20:30', text: "20:30" },
                        { id: '21:00', text: "21:00" },{ id: '21:30', text: "21:30" },{ id: '22:00', text: "22:00" },{ id: '22:30', text: "22:30" },{ id: '23:00', text: "23:00" },{ id: '23:30', text: "23:30" },
                        { id: '00:00', text: "00:00" },{ id: '00:30', text: "00:30" },{ id: '01:00', text: "01:00" },{ id: '01:30', text: "01:30" },{ id: '02:00', text: "02:00" },{ id: '02:30', text: "02:30" },
                        { id: '03:00', text: "03:00" },{ id: '03:30', text: "03:30" },{ id: '04:00', text: "04:00" },{ id: '04:30', text: "04:30" },{ id: '05:00', text: "05:00" },{ id: '05:30', text: "05:30" }                        
                    ],
                    tipo_alarma:[
                        { id:1,text:"Valor"}
                    ],
                    priodad_alarma:[
                        { id:1,text:"Alta"},
                        { id:2,text:"Media"},
                        { id:3,text:"Baja"}
                    ],
                    medication_unit:[
                        { id:0,text:"cc (Centímetros cúbicos)"},
                        { id:1,text:"mL (Mililitros)"},
                        { id:2,text:"UI (Unidad Internacional)"},
                        { id:3,text:"Tableta(s)"},
                        { id:4,text:"Gragea(s)"},
                        { id:5,text:"Cápsula(s)"},
                        { id:6,text:"Gota(s)"}
                    ],
                    amount_unit:[
                        { id:"1/4",text:"1/4"},
                        { id:"1/2",text:"1/2"},
                        { id:"1",text:"1"},
                        { id:"1 1/2",text:"1 1/2"},
                        { id:"2",text:"2"},
                        { id:"3",text:"3"},
                        { id:"4",text:"4"},
                        { id:"5",text:"5"},
                        { id:"6",text:"6"},
                        { id:"7",text:"7"},
                        { id:"8",text:"8"},
                        { id:"9",text:"9"},
                        { id:"10",text:"10"},
                        { id:"11",text:"11"},
                        { id:"12",text:"12"}
                    ],
                    frecuencia: [
                        { id: 0, text: 'Diario'},
                        { id: 1, text: 'Semanal' }
                    ],
                    time_units: [
                        { id: 0, text: 'Horas'},
                        { id: 1, text: 'Días' }
                    ],
                    tipo_alerta:[
                        { id:0,text:"Medición fuera de rango"},
                        { id:1,text:"Cumplimiento toma de mediciones"},
                        { id:2,text:"Cumplimiento toma de medicamentos"}
                    ],
                    tipo_orden:[
                        {id:0,text:'Toma de signos vitales'},
                        {id:1,text:'Medicamentos'}
                    ],
                },
                id: this.$props.label,
                showlabel: false
            }
        },
        mounted(){
            this.eval_errors()
            this.set_list()
        },
        methods:{
            emitSelection:function(){
                this.$emit('input',this.selectItems)
                this.eval_errors()
            },
            set_label:function(){
                this.showlabel = true
                return this.showlabel
            },
            eval_errors: function () {
                if (this.nameErrors.length == 0) {
                    this.$store.dispatch("puterror", { [this.id]: 0 })
                } else {
                    this.$store.dispatch("puterror", { [this.id]: 1 })
                }
            },
            set_list:function(){
                this.items=this.list[this.$props.list_type]
            },
            changeValidation:function(nuevo){
                this.fromvuex = this.$store.state.validations2[nuevo]
                if (nuevo == 'none'){
                    this.$v.selectItems.required=true
                }
                this.$v.$reset()
                this.$v.$touch()
                this.eval_errors()
            },
            reloadValidation(newValue){
                this.fromvuex = this.$store.state.validations2[newValue]
                this.$v.$touch()                    
                this.$v.$reset()
                this.eval_errors()
            }    
        },
        watch:{
            validador(newValue,oldValue) {
                if (newValue !== oldValue) {
                    this.reloadValidation(newValue)
                }
            }
        },
        computed:{
            eval_required_show: function () {
                return this.$store.state.show_required
            },            
            label_show: function () {
                let label = this.$props.label
                if (this.showlabel) {
                    label = `${this.$props.label}${'*'}`
                }
                return label
            },
            nameErrors() {
                let errors = []
                if (!this.$v.selectItems.required && this.fromvuex['required'] !== undefined) {
                    if (this.eval_required_show) {
                        errors.push('¡El campo es requerido!')
                    } else {
                        errors.push('')
                    }
                    this.set_label()
                }
                return errors
            },
            watch_list:function(){
                this.set_label()
                return this.$props.list_type
            },
            // doubleValidation:function(){
            //     let test = {}
            //     if (this.val !=this.$props.validador){
            //         test['uno'] = this.val
            //         test['dos'] = this.$props.validador
            //         this.changeValidation(this.$props.validador)
            //     } 
            //     return test
            // }         
        },
        mixins: [validationMixin],
        validations: function () {
            if (this.minl > 0) {
                return {
                    selectItems: eval(this.fromvuex)
                }
            }
            return {
                x: {
                    required
                }
            }
        }        
    }
</script>
