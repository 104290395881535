<template>
    <section>
        <TitleView title="Reportes"></TitleView>

        <v-col cols="12">
            <div>
                <v-card class="mx-auto pa-5">
                    <v-row class="mt-3">
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-0" >
                            <BaseSelect v-model="selected_report" type="text" list="ReportType" model="report" :outlined="true" filter="filter" label="Tipo de Reporte" ></BaseSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-0 col-form-icon" >
                            <BaseDate validador="required" v-model="meta.f0" label="Fecha de Creación Inicial" :outlined="true" filter="filter" :valueinit="meta.f0"></BaseDate>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-0 col-form-icon" >
                            <BaseDate validador="required" v-model="meta.f1" label="Fecha de Creación Final" :outlined="true" filter="filter" :valueinit="meta.f1"></BaseDate>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-0 btn-filter" >
                            <v-btn @click="manage_filter" class="pl-5 pr-5 white--text" block elevation="2" color="deep-purple lighten-3" :disabled="disable_buttoms"><v-icon left dark > mdi-plus-circle-outline</v-icon>Filtros</v-btn>
                            
                            <div class ="modal-fade-in" v-if="modal_filter">  
                                <table class="table">
                                    <tr>Filtros de Información</tr>
                                    <div v-for="(row,i) in filters" :key="i"> 
                                        <input :id="row.id" type="checkbox" :value="row.label" :search_list="row.list" @change="handleChange">
                                            {{ row.label}}
                                    </div>
                                </table>
                                <div class="d-flex justify-center">
                                    <v-btn @click="modal_filter=false,show=true,response={}" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                </div>
                                
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" class="pt-0 pb-0" v-for="filter in selected_filters" :key="filter.id">
                            <SelectFilter v-model="filter[3]" :model="selected_report" :label="filter[1]" :list="filter[2]" multiple :outlined="true" filter="filter" :valuex="country"></SelectFilter>  
                        </v-col>
                        <v-col cols="12" sm="6" md="4" class="pt-0 pb-0" v-if="country_filter">
                            <SelectSearch label="Pais" model="country" v-model="meta.filters.country" :outlined="true" filter="filter" :valuex="country" multiple></SelectSearch>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pa-0">
                            <v-col md="6">
                                <v-btn @click="postReport" class="pl-5 pr-5 white--text" elevation="2" block color="indigo lighten-1" :disabled="disable_buttoms"> Generar Reporte </v-btn>
                            </v-col>
                        </v-col>
                    </v-row>    
                </v-card>
            </div>     
        </v-col>

        <!-- <div class ="modal-fade-in" v-if="modal_filter">  
            <table class="table" style="width:1%">
                <tr>Filtros de Información</tr>
                <div v-for="(row,i) in filters" :key="i"> 
                    <input :id="row.id" type="checkbox" :value="row.label" :search_list="row.list" @change="handleChange">
                        {{ row.label }}
                </div>
            </table>
            <tr><td>
                <v-btn @click="modal_filter=false,show=true,response={}" class="pl-5 pr-5 white--text" depressed color="red accent-2" small>Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
            </td></tr>
        </div> -->

        <v-col cols="12">    
            <v-card class="mx-auto pa-5">

                <div style="display:none">
                    {{progressnumber}} {{inprogress}}
                </div>
                <p class="text-body-2 color-text-glya mb-0">Los reportes son <b>eliminados automáticamente a las 24 horas</b> después de su creación</p>
                <p class="text-body-2 color-text-glya">Recuerde que los informes se descargan como <b>.zip</b>, la contraseña es su número de identificación</p>    
    
                <v-data-table :headers="headers" :items="reports.data" class="elevation-1 GdiTable" item-key="name">
                    <template v-slot:[`item.type`]="{item}">
                        <p>{{item.type}}</p>
                        <small>{{item.meta.f0}} - {{item.meta.f1}}</small>
                    </template>
                    <template v-slot:[`item.status`]="{item}">
                        <span class="badge" :class="badge_color(item.status)">{{item.status}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{item}">
                        <div v-if="item.status=='inprogress'">
                            <div class="progress">
                                <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" :aria-valuenow="item.progress" aria-valuemin="0" aria-valuemax="100" :style="'width:'+item.progress+'%'">
                                <span style="color:#CCC">{{item.progress}}%</span>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="item.estado=='inline'"></div>
                        <div v-else>
                        <a :href="item.url" class="btn btn-sucess">Descargar</a>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <!-- Tabla anterior -->
        <!-- <div class="box-body">
            <div style="display:none">
                {{progressnumber}} {{inprogress}}
            </div>
            <p>Los reportes son eliminados automáticamente a las 24 horas después de su creación</p>
            <p>Recuerde que los informes se descargan como .zip, la contraseña es su número de identificación</p>    
            <table class="table">
                <thead>
                    <th>ID</th>
                    <th>Fecha Solicitud</th>
                    <th>Informe</th>
                    <th>Estado</th>
                    <th>Descargar</th>
                </thead>
                <tbody v-if="reports.data.length==0">
                    <tr>
                        <td colspan="5"><div class="callout callout-info">
                        <h4>No hay ningun reporte generado en los ultimos 15 dias</h4>
                        <h4>{{reports.data.length}}</h4>
                        </div></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for='r of reports.data' :key="r.id" :class="{'novorow' : r.status=='complete' }">
                        <td>{{r.id}}</td>
                        <td>{{r.created_at}}</td>
                        <td>{{r.type}}<br>
                        <small>{{r.meta.f0}} - {{r.meta.f1}}</small>
                        </td>
                        <td><span class="badge" :class="badge_color(r.status)">{{r.status}}</span></td>

                        <td v-if="r.status=='inprogress'">
                        <div class="progress">
                            <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" :aria-valuenow="r.progress" aria-valuemin="0" aria-valuemax="100" :style="'width:'+r.progress+'%'">
                            <span style="color:#CCC">{{r.progress}}%</span>
                            </div>
                        </div>
                        </td>
                        <td v-else-if="r.estado=='inline'"></td>
                        <td v-else>
                        <a :href="r.url" class="btn btn-sucess">Descargar</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->

    </section>
</template>

<script>
    import BaseDate from '@/components/forms_items/BaseDate.vue'
    import BaseSelect from '@/components/forms_items/BaseSelect.vue'
    import SelectFilter from '@/components/forms_items/SelectFilter.vue'
    import SelectSearch from '@/components/forms_items/SelectSearch.vue'
    import ReportServices from '@/services/ReportServices'
    import TitleView from '@/components/views/TitleView.vue'
    import axios from 'axios'
    
    export default {
        name: 'FormBasicData',
        mounted(){
            this.getreports();
            this.isloadingrepotes();
        },
        data:function() {
            return {            
                meta: { type: null, f0: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() - 1}`, f1: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,nid:"x",filters:[]},
                reports:{data:[]},
                inprogress:false,
                modal_filter:false,
                filters:[],
                selected_filters:[],
                selected_report:"",
                disable_buttoms: true,
                country_filter: false,
                eapb_filter: false,
                country: {id:[], text:"Colombia"},
                eapb: {id: "eapb", text: "EPS SANITAS-EPS005"},
                headers: [
                    { text: 'ID', value: 'id' },
                    { text: 'Fecha de solicitud', value: 'created_at' },
                    { text: 'Informe', value: 'type' },
                    { text: 'Estado', value: 'status' },
                    { text: 'Descarga', value: 'actions', active: true, sortable: false},
                ],
            }
        },
        props: {
            modal_name:{
                type: String,
            },
            label: {
                type: String
            },
            createsForm: {
                type:Boolean,
                default: false
            },
            showbutton:Boolean,
            showicon: {
                type:Boolean,
                default: true
            },
            icon: {
                type: String,
                default: "mdi-pencil-circle"
            },
            color: {
                type: String,
                default: "white"
            },
            value: {
                type: Object,
                default: function(){
                    return {}
                }
            },
            model_prop: {
                type:String,
                default: "patients"
            },
        },
        methods: {
            manage_filter: function(){
                this.resetFilter()
                if (this.selected_report == "encounter") {
                    axios.get(`${this.$URL}parameters/const=Encounter&model=report`).then(r => {
                        for (let filter in r.data.results) {
                            this.filters.push(r.data.results[filter].key)
                        }
                        console.log("Info Adicional", this.filters)
                    })
                }
                else {
                    axios.get(`${this.$URL}parameters/simpleSelect?const=Patient&model=report`).then(r => {
                        for (let filter in r.data.results) {
                            this.filters.push(r.data.results[filter].key)
                        }
                        console.log("Info Adicional", this.filters)
                    })
                }
            },
            resetFilter: function(){
                this.selected_filters = []
                this.filters = []
                this.country_filter = false
                this.eapb_filter = false
                this.modal_filter = true
            },
            handleChange: function(e) {
                const { id, value, checked} = e.target
                const search_list = e.target.attributes.search_list.value
                if (checked) {
                    if (id == "nationality" || id == "eapb") {
                        this.filterChange(id)
                    }
                    else{
                        this.selected_filters.push([id, value, search_list])
                    }
                } else {
                    if (id == "nationality" || id == "eapb") {
                        this.filterChange(id)
                    }
                    else {
                        for (const filter of this.selected_filters) {
                            if (filter[0] == id) {
                                const index = this.selected_filters.indexOf(filter)
                                this.selected_filters.splice(index, 1)
                            }
                        }
                    }
                }
            console.log("aquiiii voy", this.selected_filters)
            },
            filterChange: function (filter) {
                if (filter == "nationality") {
                    this.country_filter = !this.country_filter
                }
                else {
                    this.eapb_filter = !this.eapb_filter
                }
            },
            pruebas: function (){
                console.log("prueeeba", this.meta.filters)
            },
            postReport: function(){
                console.log("Selected Report", this.selected_report)
                console.log("Dates",this.meta.f0, this.meta.f1)
                for(let filter of this.selected_filters){
                    this.meta.filters.push([filter[0],filter[3]])
                }
                this.meta.type = this.selected_report
                axios.post(this.$URL +'/schedule_report',this.meta).then(res => {
                    console.log(res)
                });
            },
            getreports:function(){
                this.$nextTick(function(){
                    // axios.get(this.$URL + 'reports.json').then(r => {
                    ReportServices.getReports().then(r => {
                        this.reports = r
                        console.log ("Report Data without axios", this.reports.data)
                        })
                })
            },
            badge_color: function(status){
                switch (status) {
                    case 'inline':
                        return 'bg-blue';
                    case 'inprocess':
                        return 'bg-fuchsia';
                    case 'error':
                        return 'bg-red';
                    default:
                        return '';
            }
            },
            isloadingrepotes:function(){
                    setInterval(function(){
                    if (this.inprogress){
                        this.getreports()
                    } else {
                        clearInterval()
                    }
                    }.bind(this),1000)
            },
        },
        computed:{
            progressnumber:function(){
            let c = 0
            for (let rep of this.reports.data) {
                if (rep.status=="inprogress" || rep.estado=="inline" ){
                    c++;
                    console.log("ahhhhhhh",rep)
                }
            }
            if (c==0) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.inprogress=false
            } else {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.inprogress=true
            }
            return c
            },
        },
        watch: {
            selected_report: function (){
                this.disable_buttoms = false
                this.selected_filters = []
            }
        },

        components: {
        BaseDate,
        SelectFilter,
        BaseSelect,
        SelectSearch,
        TitleView
        },
    }
</script>

<style>
    .showbutton {
    justify-content: left;
    }
    .showbutton::active {
    background-color: aqua !important;
    }
    .btn-filter{
        position: relative;
    }
    .modal-fade-in{
        position: absolute;
        top:120px;
        background: #FFF;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
        z-index: 101;
        width: 90%;
    }

    @media (min-width: 960px) {
    .carerDialog{
        width: 600px;
    }
    }
</style>