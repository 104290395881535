<template>
        <v-select
            :items="datasearch"
            :class="filter"
            :solo="solo" 
            :outlined="outlined" 
            :filled="filled" 
            chips
            :label="label"
            multiple
            item-text="key" item-value="value" class="inputglya"
            v-model="valuey"
            @change="updateValue"
        ></v-select>
</template>

<script>
import axios from "axios"
export default {
    

    name:"BaseInput",

    props: {
        model:{
            type:String,
            default:'patient'
        },
        multiple:{},
        label:{},
        list:{
            type:String
        },
        solo:{
            type:Boolean,
            default:false
        },
        outlined:{     
            default:false
        },
        filled:{
            type:Boolean,
            default:false
        },
        filter:{},
    },
    model:{
        prop: "value",
        event: "blur"
    },
    data() {
        return {
            datasearch:[],
            valuey:this.$attrs.value,
        }
    },
    mounted() {
        this.searchList();
    },
    created() {

    },
    methods: {
        searchList: function () {
                axios.get(`${this.$URL}parameters/simpleSelect?const=${this.list}&model=${this.model}`).then(res => {
                    this.datasearch = res.data.results
                })
            },
        updateValue(value) {
            value=this.valuey
            this.$emit('blur', value);
            this.$emit('changeglya');
        }
    },
    computed: { 
    },
    components: {
    },
}

</script>

<style>

</style>
